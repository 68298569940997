*:focus-visible,*:focus{
    outline: unset!important;
    box-shadow: unset!important;
}
*:not(.flatpickr-input)[readonly]{
    background-color: rgba(216, 216, 216, 0.5)!important;
}
.css-ic9qte-VerticalCircleWrapper::after{
    background-color: #6366F1 !important;
}
.css-vtfjjl-Circle:not(.using-icon) {
    background-color: #6366F1 !important;
}
.css-vtfjjl-Circle:not(.using-icon).active::after{
    background-color: #FFFFFF !important;
}
.css-1f02b8t-TitleWrapper.active{
    background-color: #6366F1 !important;
    color: #ffffff !important;
}
.css-itazge-TimelineNavButton{
    background-color: #6366F1 !important;
}
.css-z4fknv-TimelineNavButton{
    background-color: #6366F1 !important;
}